import classNames from 'classnames';

import { ShapeEnum, SizeEnum, ThemeEnum, VariantEnum } from '_ui/_common/Button/interfaces';
import { ThemeDefinition, ColoredVariantDefinition, TextVariantDefinition } from '_ui/_common/Button/theme/interfaces';
import { PRIMARY, SECONDARY, PREMIUM, DARK } from '_ui/_common/Button/theme/themeDefinitions';

function getThemeStyles(theme: ThemeEnum): ThemeDefinition {
  switch (theme) {
    case ThemeEnum.SECONDARY:
      return SECONDARY;
    case ThemeEnum.PREMIUM:
      return PREMIUM;
    case ThemeEnum.DARK:
      return DARK;
    default:
      return PRIMARY;
  }
}

const shapeClassesMap = {
  default: 'rounded-md',
  rounded: 'rounded-full',
  square: 'rounded-md',
  circle: 'rounded-full',
};

const sizeClassesMap = {
  default: {
    xs: 'px-2 py-1 text-xs leading-4',
    sm: 'px-3 py-1.5 text-sm leading-4',
    base: 'px-4 py-1 text-base leading-6',
    lg: 'px-5 py-2 text-lg leading-6',
    xl: 'px-6 py-2 text-xl leading-7',
    full: 'px-4 py-1 text-base leading-6 w-full',
  },
  rounded: {
    xs: 'px-2 py-1 text-xs leading-4',
    sm: 'px-3 py-1.5 text-sm leading-4',
    base: 'px-4 py-1 text-base leading-6',
    lg: 'px-5 py-2 text-lg leading-6',
    xl: 'px-6 py-2 text-xl leading-7',
    full: 'px-4 py-1 text-base leading-6 w-full',
  },
  square: {
    xs: 'p-1 text-xs leading-4',
    sm: 'p-1.5 text-sm leading-4',
    base: 'p-2 text-base leading-6',
    lg: 'p-2.5 text-lg leading-6',
    xl: 'p-3 text-xl leading-7',
    full: 'p-2 text-base leading-6 w-full h-full',
  },
  circle: {
    xs: 'p-1 text-xs leading-4',
    sm: 'p-1.5 text-sm leading-4',
    base: 'p-2 text-base leading-6',
    lg: 'p-2.5 text-lg leading-6',
    xl: 'p-3 text-xl leading-7',
    full: 'p-2 text-base leading-6 w-full h-full',
  },
};

const defaultClasses = 'inline-flex justify-center items-center font-medium border border-solid focus:outline-none';

type ButtonGeneratedClasses = {
  buttonClasses: string;
  spinnerClasses: string;
};

type ButtonGeneratedClassesProps = {
  variant: VariantEnum;
  theme: ThemeEnum;
  shape: ShapeEnum;
  size: SizeEnum;
  disabled: boolean;
  loading: boolean;
};

export function useButtonGeneratedClasses({
  variant,
  theme,
  shape,
  size,
  disabled,
  loading,
}: ButtonGeneratedClassesProps): ButtonGeneratedClasses {
  const themeStyles = getThemeStyles(theme);

  const variantStyles: ColoredVariantDefinition | TextVariantDefinition = themeStyles[variant];

  let themeClasses = `
    text-${variantStyles.text.default}
    hover:text-${variantStyles.text.hover}
    border-${variantStyles.border.default}
    hover:border-${variantStyles.border.hover}
    shadow-${variantStyles.shadow.default}
    bg-${variantStyles.background.default}
    hover:bg-${variantStyles.background.hover}
  `;

  if ('focus' in variantStyles.background) {
    themeClasses += `focus:bg-${variantStyles.background.focus}`;
  }

  if ('ring' in variantStyles) {
    const ring = variantStyles.ring.focus;
    themeClasses += `
      focus:ring-${ring.ringWidth}
      focus:ring-${ring.ringColor}
      focus:ring-offset-${ring.ringOffsetWidth}
      focus:ring-offset-${ring.ringOffsetColor}
    `;
  }

  const spinnerClasses = `text-${variantStyles.spinColor}`;
  const shapeClasses = shapeClassesMap[shape];
  const sizeClasses = sizeClassesMap[shape][size];

  const buttonClasses = classNames(`${defaultClasses} ${themeClasses} ${shapeClasses} ${sizeClasses}`, {
    [`cursor-not-allowed`]: disabled,
    [`${variantStyles.opacity}`]: disabled && !loading,
  });

  return { buttonClasses, spinnerClasses };
}

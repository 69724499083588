import { ReactNode } from 'react';

enum VariantEnum {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

enum ThemeEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DARK = 'dark',
  PREMIUM = 'premium',
}

enum ShapeEnum {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
  SQUARE = 'square',
  CIRCLE = 'circle',
}

enum SizeEnum {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl',
  FULL = 'full',
}

enum ButtonTypeEnum {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

enum ContentPositionEnum {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

type VariantType = Lowercase<keyof typeof VariantEnum>;
type ThemeType = Lowercase<keyof typeof ThemeEnum>;
type ShapeType = Lowercase<keyof typeof ShapeEnum>;
type SizeType = Lowercase<keyof typeof SizeEnum>;
type ButtonType = Lowercase<keyof typeof ButtonTypeEnum>;
type ContentPositionType = Lowercase<keyof typeof ContentPositionEnum>;

interface ButtonProps {
  children: ReactNode;
  variant?: VariantType;
  theme?: ThemeType;
  shape?: ShapeType;
  size?: SizeType;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  tabIndex?: number;
  type?: ButtonType;
  contentPosition?: ContentPositionType;
}

interface LinkButtonProps extends ButtonProps {
  href: string;
  fullReload?: boolean;
  externalLink?: boolean;
  shallow?: boolean;
}

export { VariantEnum, ThemeEnum, ShapeEnum, SizeEnum };
export type { VariantType, ThemeType, ShapeType, SizeType, ButtonProps, LinkButtonProps };
